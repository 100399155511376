@font-face {
    font-family: "MerkurSansRegular";
    src: url("/public/css/Webfonts/EOT/MerkurSans-Regular-web.eot");
    src: url("/public/css/Webfonts/WOFF/MerkurSans-Regular-web.woff") format("woff"),
        url("/public/css/Webfonts/WOFF2/MerkurSans-Regular-web.woff2") format("woff2"),
        url("/public/css/Webfonts/OTF/MerkurSans-Regular-web.otf") format("opentype");
}

@font-face {
    font-family: "MerkurSansBlack";
    src: url("/public/css/Webfonts/EOT/MerkurSans-Black-web.eot");
    src: url("/public/css/Webfonts/WOFF/MerkurSans-Black-web.woff") format("woff"),
        url("/public/css/Webfonts/WOFF2/MerkurSans-Black-web.woff2") format("woff2"),
        url("/public/css/Webfonts/OTF/MerkurSans-Black-web.otf") format("opentype");
}

@font-face {
    font-family: "MerkurSansCondensedBlack";
    src: url("/public/css/Webfonts/EOT/MerkurSans-CondensedBlack-web.eot");
    src: url("/public/css/Webfonts/WOFF/MerkurSans-CondensedBlack-web.woff") format("woff"),
        url("/public/css/Webfonts/WOFF2/MerkurSans-CondensedBlack-web.woff2") format("woff2"),
        url("/public/css/Webfonts/OTF/MerkurSans-CondensedBlack-web.otf") format("opentype");
}

body {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    font-family: 'MerkurSansRegular';
    /* background-color: #012045; */
    background: url("/public/images/animation/img_12.jpg");
    background-size: auto;
    overflow-x: hidden;
    /* background: transparent url('/images/game/slider-horizontal-bg.png')no-repeat; */
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 99%;
}

.content {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 1;
    width: 75%;
    font-size: 3rem;
    color: #fff;
}

.mobile__view .content {
    top: 40.5%;
    z-index: 1;
    width: 100%;
}

.form-check {
    width: 100%;
}

.leaderboard {
    /* padding: 30px; */
    /* position: absolute; */
    /* width: 70%; */
    /* margin: 0 15%; */
    z-index: 1000;
    margin-top: 7%;
}

.btn {
    position: relative;
    padding-left: 15px;
    padding-right: 30px;
    background-color: transparent;
    border: none;
    /* color: #555; */
    font-size: 16px;
    cursor: pointer;
  }

  .mobile__view .btn {
    padding-left: 15px;
    padding-right: 30px;
  }

  .btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    border-top: 18px solid transparent;
    border-right: 22px solid #fff;
    border-bottom: 18px solid transparent;
  }

  .mobile__view .btn::before {
    border-bottom: 10px solid transparent;
    border-right: 15px solid #fff;
    border-top: 10px solid transparent;
    content: "";
    left: 15px;
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
  }
  .next.btn::before {
    transform: rotate(-180deg) translateY(50%);
  }

.leaderboard__title {
    /* font-size: 28px; */
    text-align: center;
    margin-bottom: 30px;
}

.leaderboard__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% 0;
}

.leaderboard__nav-button {
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.leaderboard__nav-date {
    font-size: 2.2vw;
}

.mobile__view .leaderboard__nav-date {
    font-size: 3.3vw;
}

.leaderboard__table {
    width: 80%;
    border-collapse: collapse;
    margin: 0 10%;
    /* margin-top: 5%; */
}

.mobile__view .leaderboard__table {
    width: 90%;
    margin: 0 5%;
}

.leaderboard__table th {
    background-color: #333;
    color: #fff;
    padding: 10px;
    text-align: left;
}

.leaderboard__table td {
    padding: 8px;
    /* border-bottom: 1px solid #333; */
}

.mobile__view .leaderboard__table td {
    padding: 5px;
    /* border-bottom: 1px solid #333; */
}

th,
td {
    /* padding: 0.5rem; */
    font-size: clamp(8px, 2vw, 36px);
    /* font-size: 2vw; */
    /* use relative units for font size */
}

.mobile__view th,
.mobile__view td {
    font-size: clamp(14px, 2.3vw, 36px);
}

.leaderboard__table td:last-child {
    text-align: right;
}

.leaderboard__row {
    background-color: transparent;
    color: #fff;
}

.leaderboard__row--highlight {
    background-color: #f3f3f3;
}

.leaderboard__pagination {
    display: flex;
    flex-wrap: wrap; /* allow items to wrap to the next line */
    justify-content: baseline;
    /* margin-top: 30px; */
    margin-top: 2%;
}

.leaderboard__pagination-item {
    padding: 10px 20px;
    background-color: #031938;
    color: #fff;
    border-radius: 0.5vw;
    margin-right: 10px;
    cursor: pointer;
    font-size: 16px;
    font-family: 'MerkurSans-Regular', sans;
    flex: 1 1 75px;
    margin: 3px 5px;
    /* background-color: #ddd;
    border: 1px solid #ccc; */
    /* padding: 10px; */
    text-align: center;
}

.mobile__view .leaderboard__pagination-item {
    padding: 5px;
    /* font-size: 16px; */

}

.leaderboard__pagination-item--active {
    background-color: rgb(255, 204, 0);
    font-family: 'MerkurSansBlack';
    color: #031938;
}

.leaderboard__wrapper {
    display: block;
    height: 33.3vh;
    overflow-y: auto;
    overflow-x: hidden;
    /* line-height: 1vw; */
      /* For Firefox */
  scrollbar-color: #ccc #f1f1f1;
  scrollbar-width: thin;
  
  /* For Edge */
  -ms-overflow-style: none;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f1f1f1;
  
  /* Common styles for all browsers */
  overflow-y: scroll;
  /* direction: rtl; */
  unicode-bidi: embed;
  
}

.mobile__view .leaderboard__wrapper {
    height: 33.3vw;
    margin-top: 8%;
}

.lottie-anim {
    /* position: absolute;
    width: 100%;
    height: 100%; */
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.mat-flat-button:not([class*=mat-elevation-z]),
.mat-stroked-button:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
}

.green-button {
    background: rgb(22, 219, 101);
}

.button_wrapper {
    /* width: 112%; */
    margin: 4% 0 auto;
    text-align: center;
}

.mobile__view .button_wrapper {
    margin: 1% 0 auto;
    padding: 0;
    /* margin-top: -2%; */
}

.mat-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0 auto;
    /* min-width: 100px;
    line-height: 50px;
    padding: 0 16px; */
    border-radius: 0.75vw;
    overflow: visible;
    font-family: 'MerkurSansBlack';
    font-size: 2vw;
    color: rgb(2, 38, 82);
    padding: 1vw 3vw;
}

.mobile__view .mat-button {
    font-size: 5vw;
    padding: 2vw 4vw;
}

.button__back {
    background-color: rgb(255, 204, 0);
}

.form-container {
    /* width: 112%; */
    text-align: center;
}

.mobile__view .form-container {
    width: 100%;
}

.form-container h4 {
    margin: 5%;
    /* margin-bottom: 2%; */
}

.mobile__view .form-container h4 {
    margin: 0%;
    font-size: 10vw;
    /* margin-bottom: 2%; */
}

input[type="text"] {
    width: 40vw;
    height: 5vw;
    text-align: center;
    border: none;
    border-radius: 10px;
    font-size: 2vw;
}

.mobile__view input[type="text"] {
    width: 40vw;
    height: 10vw;
    border-radius: 1.5vw;
    font-size: 5vw;
}

input[type="text"]::placeholder {
    text-align: center;
    font-size: 2vw;
}

.mobile__view input[type="text"]::placeholder {
    font-size: 4vw;
}


input[type="text"]:focus {
    outline: none;
}

.response {
    width: 100%;
    font-family: 'MerkurSansCondensedBlack';
    text-align: center;
    color: rgb(255, 204, 0);
    font-size: 3.3vw;
    margin-top: 9%;
}

.mobile__view .result {
    width: 80%;
    margin: 0 10%;
}

.mobile__view .response {
    font-size: 7vw;
}

/* For WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 2px;
    /* height: 10px; */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
  
